<template>
    <div class="wrapper container mt-5">
        <div class="bg-white p-4">
            <!-- Survey sectins -->
            <form class="mb-3" @submit.prevent="createSection()">
                <div class="row">
                    <h4>Sections</h4>

                    <div class="mb-3">
                        <label>Section Name</label>
                        <input type="text" v-model="section.name" class="form-control" placeholder="Section name"
                               required>
                    </div>

                    <div class="mb-3">
                        <label>Section Name</label>
                        <textarea class="form-control" v-model="section.description" placeholder="Section description"
                                  rows="5"></textarea>
                    </div>

                    <div class="mb-3">
                        <button type="submit">Submit</button>
                        <button @click.prevent="clearSection()">Clear</button>
                    </div>
                </div>
            </form>

            <table class="table table-sm">
                <thead>
                <tr>
                    <th>NAME</th>
                    <th style="width: 60%">DESCRIPTION</th>
                    <th>QUESTIONS</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in sections" :key="i.id">
                    <td>{{ i.name }}</td>
                    <td>{{ i.description }}</td>
                    <td>{{ i.questions_count }}</td>
                    <td>
                        <button @click.prevent="editSection(i)" class="btn btn-secondary btn-sm">Edit</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "SurveySections",
    props: ['code'],
    data() {
        return {
            survey: null,
            sections: [],
            section: {
                id: '',
                name: '',
                description: ''
            }
        }
    },
    mounted() {
        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
        }).catch(error => {
            this.showError(error)
        });

        this.loadSections()
    },
    methods: {
        createSection() {
            this.$http.post(`/api/profile/survey/${this.code}/sections`, this.section).then(() => {
                this.loadSections()
                this.section.name = ''
                this.section.description = ''
            }).catch(error => {
                this.showError(error)
            });
        },
        loadSections() {
            this.$http.get(`/api/profile/survey/${this.code}/sections`).then(response => {
                this.sections = response.data.data
            })
        },
        editSection(section) {
            this.section = section
        },
        clearSection() {
            this.section.id = ''
            this.section.name = ''
            this.section.description = ''
        }
    }
}
</script>

<style scoped>

</style>